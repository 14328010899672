const moment = require('moment');
const getSimpleDateFR = date => {
    if (!date) return date;
    if (typeof date == 'string' && date.includes('/')) {
        return date;
    }
    return moment(date).format('DD/MM/YYYY');
}
const getSimpleDateTimeFR = date => {
    if (!date) return date;
    if (typeof date == 'string' && date.includes('/')) {
        return date;
    }
    return moment(date).format('DD/MM/YYYY HH:mm');
}

const toCurrency = val => new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
}).format(val);

const convertToSymbol = val => {
    if (!val) return '';
    if (!val.includes(' EUR')) return val;
    return toCurrency(parseFloat(val.replace(' EUR', '')));
}

const getTitle = commande => {
    let customer = commande.customer;
    let name = customer ? (customer.first_name + ' ' + customer.last_name) : 'Inconnu';
    return `${name} ${commande.collection_code?.toUpperCase() || commande.id.toUpperCase()}`
}

const COLOR = {
    light_blue: '#e6f3fe',
    dark_blue: '#033076',
    red: '#ff3823',
    green: '#72bb53',
    background_disabled: '#6c757d',
    foreground_disabled: '#fff'
}

const FONT_SIZE = {
    xlarge: 60,
    large: 40,
    medium: 25,
    small: 18,
    xsmall: 13
}

module.exports = {
    getSimpleDateFR,
    getSimpleDateTimeFR,
    toCurrency,
    getTitle,
    convertToSymbol,
    COLOR,
    FONT_SIZE
}