import { graphql, useStaticQuery } from "gatsby";
import {
  Box, Grommet, ResponsiveContext, ThemeContext
} from "grommet";
import { grommet } from "grommet/themes";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import "./layout.css";


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);


  return (
    <Grommet
      theme={{ ...grommet }}
      style={{
        display: "flex",
        fontFamily: 'TitilliumWeb-Regular',
        flexDirection: "column",
      }}
    >
      <Helmet>
        <script src="/libs/epos-2.22.0.js"></script>
      </Helmet>
      <ThemeContext.Extend
        value={{
          global: { colors: { brand: "#E92C70" } },
          rangeInput: {
            track: {
              height: "10px",
              lower: {
                color: "#59A4F4",
                opacity: 0.7,
              },
              upper: {
                color: "dark-4",
                opacity: 0.3,
              },
            },
            thumb: {
              color: "#fff",
            },
          },
        }}
      >
        <ResponsiveContext.Consumer>
          {(size) => (
            <Box full="vertical" style={{ height: '100%', minHeight: '100vh' }}>
              {children}

            </Box>
          )}

        </ResponsiveContext.Consumer>
      </ThemeContext.Extend>
    </Grommet>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
