const axios = require('axios');
const moment = require('moment');
const config = require('../config/config.json');
const data = require('../config/data.json');
const { getOrderToken, getCatalogObj, getLocation, getCatalogToken, setAllCatsData } = require('./storage');

const getOrderHeaders = () => {
    return {
        'X-Access-Token': getOrderToken()
    }
}

const getCatalogHeaders = () => {
    return {
        'X-Access-Token': getCatalogToken()
    }
}

const getNewCommandes = () => {
    let headers = { ...getOrderHeaders() };
    let params = {
        status: 'new',
        after: moment().subtract(60, 'minutes').format('YYYY-MM-DDTHH:mm:ss+02:00')
    }
    let url = `/location/orders?status=new&after=${params.after}`;
    logActions(url, new Date(), 'GET');
    return axios.get(`${config.hubrise_api}/location/orders`, { headers, params });
}

const ping = () => {
    let location = getLocation();
    let body = {
        location_id: location.id,
        location_name: location.name
    }
    return axios.post(`${config.forwardServer}/connection/ping`, body);
}

const getLocationProfile = (token) => {
    let headers = { 'X-Access-Token': token };

    return axios.get(`${config.hubrise_api}/location`, { headers });
}

const updateRestaurantStatus = (status) => {
    let headers = { ...getOrderHeaders() };
    return axios.patch(`${config.forwardServer}/restaurant/status`, { headers, status });
}

const getLatestCommandes = () => {
    let headers = { ...getOrderHeaders() };
    let params = {
        after: moment().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss+02:00')
    }
    let url = `/location/orders?status=new&after=${params.after}`;
    logActions(url, new Date(), 'GET');
    return axios.get(`${config.hubrise_api}/location/orders`, { headers, params });
}

const changeStatus = (commande, status) => {
    let headers = { ...getOrderHeaders() };
    let url = `/location/order/${commande.id}?status=${status}`;
    logActions(url, new Date(), 'PATCH');
    return axios.patch(`${config.forwardServer}/order/${commande.id}`, { status: status, headers });
}


const getAllCommandes = async (date) => {
    let headers = { ...getOrderHeaders() };
    let url = `/location/orders?when=lastweek`;
    logActions(url, new Date(), 'GET');
    let res = await axios.post(`${config.forwardServer}/order/last-week`, { headers });
    return res.data;
}

const authorizeHubrise = (code, client_id, client_secret) => {
    var bodyFormData = new FormData();
    bodyFormData.append('code', code);
    bodyFormData.append('client_id', client_id);
    bodyFormData.append('client_secret', client_secret);
    return axios.post(`${config.forwardServer}/oauth/token`, bodyFormData, { headers: { "Content-Type": "multipart/form-data" } })
}

const getAllCatalogs = () => {
    let headers = { ...getCatalogHeaders() };
    return axios.post(`${config.forwardServer}/catalogs`, { headers });
}

const getCatalog = (catalog) => {
    let headers = { ...getCatalogHeaders() };
    return axios.get(`${config.hubrise_api}/catalogs/${catalog?.id}`, { headers });
}

const getAllProducts = (catalogId) => {
    let headers = { ...getCatalogHeaders() };
    return axios.get(`${config.hubrise_api}/catalogs/${catalogId}/products`, { headers });
}

const getAllOptions = (catalogId, option_lists_id) => {
    let headers = { ...getCatalogHeaders() };
    return axios.get(`${config.hubrise_api}/catalogs/${catalogId}/option_lists/${option_lists_id}`, { headers });
}

const getOption = (catalogId, option_id) => {
    let headers = { ...getCatalogHeaders() };
    return axios.get(`${config.hubrise_api}/catalogs/${catalogId}/options/${option_id}`, { headers });
}

const getInventory = (catId) => {
    let headers = { ...getCatalogHeaders() };
    return axios.get(`${config.hubrise_api}/catalogs/${catId}/location/inventory`, { headers });
}

const updateInventory = (catId, inventories) => {
    let body = {
        headers: { ...getCatalogHeaders() },
        body: inventories
    }
    return axios.patch(`${config.forwardServer}/catalogs/${catId}/inventory`, body)
}

const syncCatalogs = async () => {
    let location = getLocation();
    let locationName = location.name;
    let allCats = [];
    let res = await getAllCatalogs();
    for (let catalog of res.data) {
        const loadedOptions = [];

        if (!catalog.name.trim().toUpperCase().includes(locationName.trim().toUpperCase())) {
            continue;
        }
        let resProds = await getAllProducts(catalog.id);
        let cat = { id: catalog.id, name: catalog.name, plats: [] };
        for (let p of resProds.data) {
            if (p.skus) {
                for (let sku of p.skus) {
                    if (sku.name) sku.name = `${catalog.name}-${p.name}-${sku.name}`
                    else sku.name = `${catalog.name}-${p.name}`;
                    let options = [];
                    for (let optListId of sku.option_list_ids) {
                        let existing = loadedOptions.find(o => o.listId === optListId);
                        if (existing) {
                            options.push(JSON.parse(JSON.stringify(existing.data)));
                        } else {
                            let res = await getAllOptions(catalog.id, optListId);
                            options.push(res.data);
                            loadedOptions.push({
                                listId: optListId,
                                data: JSON.parse(JSON.stringify(res.data))
                            })
                        }

                    }
                    sku.options = options;
                }
                cat.plats = cat.plats.concat(p.skus);
            }
        }
        allCats.push(cat);
    }
    setAllCatsData(allCats);
}


const logActions = (url, time, method) => {
    let location = getLocation();
    if (!location) return;
    let body = {
        location_id: location.id,
        url, time, method
    }
    return axios.post(`${config.forwardServer}/logs`, body);
}

module.exports = {
    getLatestCommandes,
    getNewCommandes,
    getAllCommandes,
    getAllCatalogs,
    getCatalog,
    getAllProducts,
    getAllOptions,
    getOption,
    getInventory,
    updateInventory,
    changeStatus,
    authorizeHubrise,
    getLocationProfile,
    ping,
    syncCatalogs,
    updateRestaurantStatus,
    logActions,
    checkMigration: () => {
        const hubrise_id = getLocation().id;
        const params = {
            "_where[0][hubrise_id]": hubrise_id,
            "_where[0][migratable]": true,
        }
        return axios.get(`${config.forwardServer}/migration`, { params });
    },
    updateMigration: (migration) => {
        return axios.put(`${config.forwardServer}/migration/${migration.id}`, migration);
    }
}